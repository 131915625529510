<template>
  <div :class="this.$device.mobile || this.$device.ipad ? 'client-mobile client-detail' : 'client-detail client-pc d-flex align-items-center app-customer min-vh-100'">
    <router-view/>
  </div>
</template>
<script>
export default {
    mounted() {
      document.body.style.background = "transparent";
    },
};
</script>


<style lang="scss">
  @import '~@/assets/app.scss';
</style>
