import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createDeviceDetector } from 'next-vue-device-detector'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min.js"
import "bootstrap-icons/font/bootstrap-icons.min.css"

const app = createApp(App);
export const device = createDeviceDetector();

require('@/config/axios');

app.use(router).use(device).mount('#app')